.feed__box {
  border-radius: 15px;
}

.feed__box:hover {
  border: solid 2px white;
}

.feed__filter {
  filter: drop-shadow(0px 0px 0px gray);
  transition: all 0.5s;
}
.feed__filter:hover {
  filter: drop-shadow(2px 5px 5px gray);
}
