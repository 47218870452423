.enroll__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: darkgray;
}

.enroll__text {
  font-size: calc(36px + (14 - 7) * ((100vw - 300px) / (960 - 300)));
  line-height: 1;
  font-weight: bolder;
}

.enroll__subtext {
  font-size: calc(30px + (14 - 9) * ((100vw - 300px) / (960 - 300)));
  line-height: 1;
  font-weight: bolder;
}

.enroll__unitBox {
  margin-bottom: calc(25px + (14 - 11) * ((100vw - 300px) / (960 - 300)));
  text-transform: uppercase;
}

.enroll__unit {
  font-size: calc(16px + (14 - 11) * ((100vw - 300px) / (960 - 300)));
  line-height: 0.5;
  font-weight: 700;
}

.enroll__date {
  font-size: calc(10px + (14 - 11) * ((100vw - 300px) / (960 - 300)));
  line-height: 2;
  font-weight: 500;
  color: rgb(226, 226, 226);
}
