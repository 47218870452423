.__dropdown-menu {
  position: absolute;
  width: auto;
  left: auto;
  right: 0;
  background: white;
  padding: 1%;
}

.__mgradblue {
  height: 300px;
  background: url(https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-repeat: no-repeat;
  background-position: center center;
}

.__profile_photo {
  width: 25px;
  height: 25px;
  border-radius: 25%;
  border-style: none;
}
