@font-face {
  font-family: gotham;
  src: url(./components/assets/font/Gotham-Light.otf);
}
@font-face {
  font-family: gotham;
  src: url(./components/assets/font/GothamMedium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: gotham;
  src: url(./components/assets/font/GothamBold.ttf);
  font-weight: 700;
}

body {
  margin: 0;
  font-family: gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}
