.gradblue {
  background: rgb(0 212 255/0.7);
  background: linear-gradient(135deg,
      rgb(0 212 255 /0.7) 0%,
      rgb(65 105 178 /0.7) 100%);
  color: #ffffff;
  padding: 7px;
}

.nav-tetep {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.gradred {
  background: rgb(225 38 39 /1);
  background: linear-gradient(137deg,
      rgb(225 38 39 /1) 0%,
      rgb(155 35 42 /1) 100%);
  color: #ffffff;
  padding: 7px;
}

.btnblue {
  display: inline-block;
  padding: 7px 30px;
  margin: 0px 15px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  border: 3px solid rgb(65 105 178 /1);
}

.btnred {
  display: inline-block;
  padding: 7px 30px;
  margin: 0px 15px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  border: 3px solid rgb(155 35 42 /1);
}

.navbar {
  color: #ffffff;
}

.nav-item {
  display: inline-block;
  padding: 7px;
}

.navbar-nav {
  display: block;
}

.navbar-brand {
  color: #ffffff;
}

.nav-link {
  color: #ffffff;
}

span.glyphicon {
  padding-right: 10px;
}